import axios from "../axios";
import { Cookies } from "react-cookie";
const endPoint = `/channels/`

const cookie = new Cookies();

export const createchannel = async ({name}) => {
    const token=cookie.get("crm")
    
    const res = await axios.post(`${endPoint}createone`,{name},{headers:{Authorization:token}});
    return res
};

export const getmine = async () => {
    const token=cookie.get("crm")
    const res = await axios.get(`${endPoint}my`,{headers:{Authorization:token}});
    return res
};