import React, { useState, useEffect } from 'react';
import { getassoiciated,getAssignements } from '../../network/api/freelance';

function ShowUsers() {
  const [pageTable, setPageTable] = useState(1)
  const [data, setdata] = useState([]);
  const [icode, seticode] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [assignements, setAssignements] = useState(null);


  const handleDecreasePageTable = () => {
    if (pageTable > 1) {
      setPageTable(pageTable - 1)
    }
  }

  const handleIncreasePageTable = () => {
    if (pageTable < Math.ceil(data.length / 12)) {
      setPageTable(pageTable + 1)
    }
  }

  useEffect(() => {
    getassoiciated().then(result => {
      setdata(result.data.response);
      seticode(result.data.code);
    });
  }, []);

  const toggleOverlay = (user) => {
    setSelectedUser(user === selectedUser ? null : user);
  };
  useEffect(()=>{
    if(selectedUser!==null){
      getAssignements(selectedUser._id).then(result => {
        setAssignements(result.data.history);
        
      });
    }
  },[selectedUser])

  return (
    <>
      <div className='data-table'>
        <h1>associated users</h1>
        <div className='w-100 d-flex flex-column align-items-end ps-4'>
          <h5>Invitation code : {icode}</h5>
          <div className='table overflow-x-scroll w-100'>
            <table style={{ borderCollapse: 'collapse', borderBottom: '1px solid black' }}>
              <thead>
                <tr className=''>
                  <th>name</th>
                  {/* <th>phone</th> */}
                  <th>phone</th>
                  <th>email</th>
                  <th>role</th>
                  <th>assignement history</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  if (index >= ((pageTable - 1) * 12) && index < pageTable * 12) {
                    return (
                      <tr key={item._id} id={`row${item._id}`}>
                        <td>{item.name}</td>
                        <td>{item.phone}</td> 
                        <td>{item.email}</td>
                        <td>{item.role}</td>
                        <td>
                          <button onClick={() => toggleOverlay(item)}>Show Details</button>
                        </td>
                      </tr>
                    )
                  }
                })}
              </tbody>
            </table>
          </div>
          <div className='filter py-2 px-5 '>
            <p onClick={() => setPageTable(1)}>&lt;&lt;</p>
            <p onClick={handleDecreasePageTable}>&lt;</p>
            <input 
              type="number" 
              value={pageTable} 
              onChange={(e) => setPageTable(parseInt(e.target.value))}
            />
            <p onClick={handleIncreasePageTable}>&gt;</p>
            <p onClick={() => setPageTable(Math.ceil(data.length / 12))}>&gt;&gt;</p>
          </div>
        </div>
      </div>
      {/* Overlay to show user details */}
      {selectedUser && (
        <div className="overlay">
          <div className="overlay-content">
            <h2>User Details</h2>
            <p>Name: {selectedUser.name}</p>
            <p>Phone: {selectedUser.phone}</p>
            <p>Email: {selectedUser.email}</p>
            <div className='d-flex justify-content-around'><p>Count</p><p>Date</p></div>
            {assignements?.map((assignement)=>(
              <div className='d-flex justify-content-around'><p>{assignement.count}</p><p>{assignement.createdAt.slice(0,10)}</p></div>
            ))}
            <button onClick={() => toggleOverlay(selectedUser)}>Close</button>
          </div>
        </div>
      )}
    </>
  )
}

export default ShowUsers;
