import React, { useEffect, useState } from 'react';
import './left_panel.css';
import { Link } from 'react-router-dom';
import { BiSolidHome } from 'react-icons/bi';
import { SiGoogleadsense } from 'react-icons/si';
import { TbSettingsFilled } from 'react-icons/tb';
import { HiUsers } from 'react-icons/hi';

function LeftSide({ setpage, info, setShowSide }) {
  const [sittingsVisible, setsittingsVisible] = useState(false);
  const [leadVisible, setleadVisible] = useState(false);
  const [userVisible, setUserVisible] = useState(false);
  const [types, settypes] = useState(false);
  useEffect(() => {
    settypes(!info?.freelancer)
  }, [info])
  const toggleSittings = () => {
    setsittingsVisible(!sittingsVisible);
  };
  const toggleUsers = () => {
    setUserVisible(!userVisible);
  };

  const toggleLeads = () => {
    setleadVisible(!leadVisible);
  };

  const changePage = (page) => {
    setpage(page);
  };


  return (
    <div className='left-panel top-0 sticky-top bg-white pt-5 '>
      <div className='left-panel-item mt-5'>
        <span className='cancel d-xl-none' onClick={() => setShowSide(false)}
        >x</span>
        <div className='my-3'>
          <div className='left-panel-item left-sub-item'>
            <Link to='/home' onClick={() => { changePage('Home'); setShowSide(false) }} className='home-item d-flex align-items-start'>
              <div className='item'>
                <span className='icon me-3'><BiSolidHome /></span> <span>Home</span>
              </div>
            </Link>
          </div>
          <div className='left-panel-item left-sub-item'>
            <div className='item' onClick={toggleLeads}>
              <span className='icon me-3'><SiGoogleadsense /></span> <span>Leads</span>
            </div>
            {leadVisible && (
              <div className='list'>
                <Link to='import-leads' onClick={() => changePage('Import Leads')}>Import Leads</Link>
                <Link to='add-single' onClick={() => changePage('Add Single Lead')}>Add Single Lead</Link>
                {types && (<><Link to='all-leads-c' onClick={() => changePage('All Leads')}>Company Leads</Link>
                {/* <Link to='Assigned' onClick={() => changePage('Assigned leads')}>All Leads</Link> */}
                <Link to='associated' onClick={() => changePage('Users added')}>Users added</Link>
                {/* <Link to='marketing' onClick={() => changePage('marketing')}>Marketing</Link> */}
                </>)}
                <Link to='all-leads' onClick={() => changePage('All Leads')}>All Leads</Link>
                <Link to='fresh-leads' onClick={() => changePage('Fresh Leads')}>Fresh Leads</Link>
                <Link to='delay-leads' onClick={() => changePage('delay Leads')}>Delay Leads</Link>
                <Link to='follow-leads' onClick={() => changePage('follow Leads')}>Follow Leads</Link>
                <Link to='done-leads' onClick={() => changePage('done Leads')}>Done Leads</Link>
                <Link to='no-follow-leads' onClick={() => changePage('no follow Leads')}>no follow Leads</Link>
              </div>
            )}
          </div>
            <div className='left-panel-item left-sub-item'>
            <div className='item' onClick={toggleSittings}>
              <span className='icon me-3'><TbSettingsFilled /></span> <span>Settings</span>
            </div>
            {sittingsVisible && (
              <div className='list'>
                <Link to='add-channel' onClick={() => changePage('Add Channel')}>Add Channel</Link>
                <Link to='add-project' onClick={() => changePage('Add Project')}>Add Project</Link>
                <Link to='add-action' onClick={() => changePage('Add Action')}>Add Action</Link>
                <Link to='add-column-header' onClick={() => changePage('Add Column Header')}>Add Column Header</Link>
                <Link to='uploads' onClick={() => changePage('uploads')}>uploads</Link>
                {!types&&<Link to='join-company' onClick={() => changePage('Joincompany')}>JoinCompany</Link>}
              </div>
            )}
          </div>
          {types && (<div className='left-panel-item left-sub-item'>
            <div className='item' onClick={toggleUsers}>
              <span className='icon me-3'><HiUsers /></span> <span>Users</span>
            </div>
            {userVisible && (
              <div className='list'>
                <Link to='show-users' onClick={() => changePage('Show Users')}>Show Users</Link>
                <Link to='add-users' onClick={() => changePage('Add Users')}>Add Users</Link>
                <Link to='requests' onClick={() => changePage('Requests')}>Requests</Link>
                <Link to='marketing-counter' onClick={() => changePage('Marketing')}>Marketing counter</Link>
              </div>
            )}
          </div>)}
        </div>
      </div>
    </div>
  );
}

export default LeftSide;
