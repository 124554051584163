import { useContext, useEffect, useState } from 'react';
import Image from '../../images/man.svg';
import { AssignDivContext } from './Marketing';
import { getassoiciated } from '../../network/api/freelance';
function Assign() {
  const { showAssign, setShowAssign,handleAssignAction,handleselfAssignAction } = useContext(AssignDivContext);
  const [users,setUsers]=useState([])
  const [user, setUser] = useState("");
  const [username, setUserName] = useState("");
  const handleAgree = () => {
    handleAssignAction({broker:user,brokername:username})
    //action...............
  }
  useEffect(()=>{
    getassoiciated().then(result=>{
      if(result.data.response.length===0)
        setShowAssign(false)
      else
      setUsers(result.data.response)
    })
  },[])
  
  return (
    <div className="assign-div">
      <div>
        <div className='back'>
          <span className='' onClick={() => setShowAssign(false)}>X</span>
        </div>
        <h2 className='mb-5'>Assign Leads To :</h2>
        <div className="people">
          <ul>
            {users.map((item) => (
              <li className='d-flex align-items-start' key={item._id} onClick={() => {setUser(item._id);setUserName(item.name)}}>
                <img src={Image} width={50} height={50} className='pt-1' />
                <div className='d-flex flex-column align-items-start ms-4 '>
                  <p className='m-0 name'>{item.name} </p>
                  <p className='title'>{item.role}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className='d-flex flex-row-reverse justify-content-between align-items-end mt-2 ms-4'>
        <button className='agree align-self-start float-right' onClick={()=>{handleselfAssignAction()}}>selfassign</button>
          {user == 0 ?
            <button className='no-agree align-self-start float-right' disabled>Agree</button>
            :
            <button className='agree align-self-start float-right' onClick={handleAgree}>Agree</button>
          }
          {users.map((item) => {
            if (item._id == user) {
              return (
                <div className='d-flex  align-self-end  '>
                  <img src={Image} width={50} height={50} className='pt-1' />
                  <div className='d-flex flex-column align-items-start ms-4 '>
                    <p className='m-0 name'>{item.name}</p>
                    <p className='title'>{item.role}</p>
                    <p className='title'>{item.phone}</p>
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}
export default Assign

