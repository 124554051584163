import { useEffect, useState } from "react";
import * as channelsapi from '../../network/api/channels'
import * as projectsapi from '../../network/api/projects'
import { createsingle } from "../../network/api/leads";

function AddSingleLead() {
  const [name, setName] = useState();
  const [info, setinfo] = useState();
  const [project, setproject] = useState("");
  const [channel, setchannel] = useState("");
  const [comment, setcomment] = useState("");
  const [mobile, setmobile] = useState();
  const [destination, setDestination] = useState();

  const [fresh] = useState(true);

  const handleProjectChange = (e) => {
    setproject(e.target.value);

  };
  const handleChanalChange = (e) => {
    setchannel(e.target.value);
  };
  const [channels, setchannels] = useState([]);
  const [projects, setprojects] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();
    createsingle({
      name: name,
      info: info,
      mobile: mobile,
      project:project,
      channel:channel,
      destination:destination,
      comment:comment,
      fresh:fresh
    }).then((result) => {
      window.location.reload();
    });
  };
  useEffect(() => {
    channelsapi.getmine().then(result => { setchannels(result.data.response) })
    projectsapi.getmine().then(result => { setprojects(result.data.response) })
  }, [])
  return (
    <div className="users">
      <div className="add-user">
        <h1>Add Single Lead</h1>
        <form onSubmit={(e) => handleSubmit(e)}>
          <input
            type="text"
            name="name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter The User Name ......"
          />
          <input
            type="text"
            name="info"
            value={info}
            onChange={(e) => setinfo(e.target.value)}
            placeholder="Enter The User info ......"
          />

          <input
            type="text"
            name="mobile"
            value={mobile}
            onChange={(e) => setmobile(e.target.value)}
            placeholder="Enter The User mobile ......"
          />
          <input
            type="text"
            name="destination"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
            placeholder="Enter The User destination ......"
          />
          <input
            type="text"
            name="comment"
            value={comment}
            onChange={(e) => setcomment(e.target.value)}
            placeholder="Enter comment ......"
          />
          <select required className='btn border-dark col-12 col-lg-3 text-secondary flex-grow-1 '
          style={{ textAlign: "left", fontSize: "1.2rem" }}
          value={project} onChange={handleProjectChange}>
          <option value="" disabled selected className='text-secondary-subtle'>Project</option>
          {projects?.map((project, index) => (
            <option value={project.name}>{project.name}</option>
          ))}
        </select>
        <select required className='btn border-dark col-12 col-lg-3 text-secondary flex-grow-1 '
          style={{ textAlign: "left", fontSize: "1.2rem" }}
          value={channel} onChange={handleChanalChange}>
          <option value="" disabled selected className='text-secondary-subtle' >channel</option>
          {channels?.map((channel, index) => (
            <option value={channel.name}>{channel.name}</option>
          ))}
        </select>
       
          <div className="submit">
            <input type="submit" value="Save" />
          </div>
        </form>
      </div>
    </div>
  );
}
export default AddSingleLead;
