import axios from "../axios";
import { Cookies } from "react-cookie";
const endPoint = `/clock/`

const cookie = new Cookies();

export const loginclock = async () => {
    const token=cookie.get("crm")
    const res = await axios.post(`${endPoint}login`,{},{headers:{Authorization:token}});
    return res
};

export const logout = async () => {
    const token=cookie.get("crm")
    const res = await axios.post(`${endPoint}logout`,{},{headers:{Authorization:token}});
    return res
};