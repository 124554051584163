import { useState } from "react";

function EditLead() {
  const [name, setName] = useState('Youssef Mohamed')
  const [phone, setPhone] = useState('')
  const [channel, setChannel] = useState('')
  const [project, setProject] = useState('')
  const [saleman, setSaleman] = useState('')
  const [comment, setComment] = useState('')
  const [job, setJob] = useState('')

  return (
    <div className="edit-lead w-100 p-4">
      <div className="d-flex">
        <div className="ed-item d-flex flex-column align-items-start flex-grow-1  me-4">
          <p className="mb-1 fs-4 " style={{ fontWeight: '700' }}>Name </p>
          <input name="name" value={name} onChange={(e) => setName(e.target.value)} className="w-100" />
        </div>
        <div className="d-flex flex-column align-items-start flex-grow-1">
          <p className="mb-1 fs-4 " style={{ fontWeight: '700' }}>Phone </p>
          <input name="name" value={phone} onChange={(e) => setPhone(e.target.value)} className="w-100" />
        </div>
      </div>
      <div className="d-flex mt-5">
        <div className="ed-item d-flex flex-column align-items-start flex-grow-1  me-4">
          <p className="mb-1 fs-4 " style={{ fontWeight: '700' }}>channel </p>
          <input name="name" value={channel} onChange={(e) => setChannel(e.target.value)} className="w-100" />
        </div>
        <div className="d-flex flex-column align-items-start flex-grow-1">
          <p className="mb-1 fs-4 " style={{ fontWeight: '700' }}>project </p>
          <input name="name" value={project} onChange={(e) => setProject(e.target.value)} className="w-100" />
        </div>
      </div>
      <div className="d-flex mt-5">
        <div className="ed-item d-flex flex-column align-items-start flex-grow-1">
          <p className="mb-1 fs-4 " style={{ fontWeight: '700' }}>Comment </p>
          <textarea name="name" value={comment} onChange={(e) => setComment(e.target.value)} className="w-100"
            style={{ height: '200px' }}
          ></textarea>
        </div>
      </div>
      <div className="d-flex mt-5">
        <div className="ed-item d-flex flex-column align-items-start flex-grow-1  me-4">
          <p className="mb-1 fs-4 " style={{ fontWeight: '700' }}>Saleman </p>
          <input name="name" value={saleman} onChange={(e) => setSaleman(e.target.value)} className="w-100" />
        </div>
        <div className="d-flex flex-column align-items-start flex-grow-1">
          <p className="mb-1 fs-4 " style={{ fontWeight: '700' }}>Job </p>
          <input name="name" value={job} onChange={(e) => setJob(e.target.value)} className="w-100" />
        </div>
      </div>
      <button className="save">
        save
      </button>
    </div>
  )
}
export default EditLead;