import React, { useState, useEffect } from 'react';
import { getfiles} from '../../network/api/leads';

function UploadedFiles() {
  const [pageTable, setPageTable] = useState(1)

  const [data, setdata] = useState([]);
  const handleDecreasePageTable = () => {
    if (pageTable > 1) {
      setPageTable(pageTable - 1)
    }
  }
  const handleIncreasePageTable = () => {
    if (pageTable < Math.ceil(data.length / 20))
      setPageTable(pageTable + 1)
  }
  useEffect(() => {
    load()
  }, [])
  const load=()=>{
    getfiles().then(result => {
      setdata(result.data.response)
      console.log(result.data.response)
    })
  }

  return (
    <>
      <div className='data-table'>
        <h1>uploads</h1>
        <div className='w-100 d-flex flex-column align-items-end ps-4'>
          <div className='table overflow-x-scroll w-100'>
            <table style={{ borderCollapse: 'collapse', borderBottom: '1px solid black' }}>
              <thead>
                <tr className=''>
                  <th>Date</th>
                  <th>download</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  if (index >= ((pageTable - 1) * 20) && index < pageTable * 20) {
                    return (
                      <tr
                        key={item.Id}
                        id={`row${item._id}`}
                      >
                        <td>{item.name}</td>
                        <td><a href={'https://crm.3aqaar.com/xlxsuploads/'+item.name}>Link</a></td>
                      </tr>
                    )
                  }
                })}
              </tbody>
            </table>
          </div>
          <div className='filter py-2 px-5 '>
            <p onClick={() => setPageTable(1)}>&lt;&lt;</p>
            <p onClick={() => handleDecreasePageTable()}>&lt;</p>
            <p>{pageTable}</p>
            <p onClick={() => handleIncreasePageTable()}>&gt;</p>
            <p onClick={() => setPageTable(Math.ceil(data.length / 20))}>&gt;&gt;</p>
          </div>
        </div>
      </div>
    </>
  )
}
export default UploadedFiles;


