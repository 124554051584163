import { useEffect, useState } from 'react'
import Dropzone from '../Dropzone';
import WhatsIcon from '../../images/whatsapp.svg'
import CallIcon from '../../images/call.svg';
import { createchannel ,getmine} from '../../network/api/channels';
function AddChannel({ list }) {
  const [name, setName] = useState();

  const [data, setData] = useState([]);
  const [phoneStr, setphoneStr] = useState()
  const [showAlert, setShowAlert] = useState(false)

  const [pageTable, setPageTable] = useState(1)
  const load=()=>{
    getmine().then(result=>{
      setData(result.data.response)
    })
  }
  useEffect(()=>{
    load()
  },[])



  const handleDecreasePageTable = () => {
    if (pageTable > 1) {
      setPageTable(pageTable - 1)
    }
  }
  const handleIncreasePageTable = () => {
    if (pageTable < Math.ceil(data.length / 12))
      setPageTable(pageTable + 1)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    createchannel({name}).then(()=>load())
    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
    }, 2000);
  }

  return (
    <>
      <div className="settings">
      {showAlert &&
        <div className='alert-done position-fixed px-4 py-3 '>Well Done !!</div>
      }
        <div className='add-channel'>
          <h1>Add Channel</h1>
          <form onSubmit={(e) => handleSubmit(e)}>
            <input
              type='text'
              name='name'
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='Enter The Channel Name ......'
            />
            
            <div className='submit'>
              <input type='submit' value='Save' />
            </div>
          </form>
        </div>
      </div>
      <div className='data-table mt-0 p-0 m-0 ' >
        <h1>Channels Table</h1>
        <div className='w-100 d-flex flex-column align-items-end ps-4'>
          
          <div className='table overflow-x-scroll w-100'>
            <table style={{ borderCollapse: 'collapse', borderBottom: '1px solid black' }}>
              <thead>
                <tr className=''>
                  <th></th>
                  <th> Channel Name</th>
                  <th>Created on </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => {
                  if (index >= ((pageTable - 1) * 12) && index < pageTable * 12) {
                    return (
                      <tr
                        key={item.id}
                        id={`row${item.id}`}
                        
                      >
                         <td ></td>
                        <td >{item.name}</td>
                        <td>{item.createdAt?.slice(0,10)}</td>

                        
                      </tr>
                    )
                  }
                })}
              </tbody>
            </table>
          </div>
          <div className='filter py-2 px-5 '>
            <p onClick={() => setPageTable(1)}>&lt;&lt;</p>
            <p onClick={() => handleDecreasePageTable()}>&lt;</p>
           <input 
  type="number" 
  value={pageTable} 
  onChange={(e) => setPageTable(parseInt(e.target.value))}
/>

            <p onClick={() => handleIncreasePageTable()}>&gt;</p>
            <p onClick={() => setPageTable(Math.ceil(data?.length / 12))}>&gt;&gt;</p>
          </div>
        </div>
      </div>
    </>
  )
}
export default AddChannel