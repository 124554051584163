import React, { useEffect, useState } from "react";
import { BiSolidUser, BiSolidHome, BiPhoneCall } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import { FaFan } from "react-icons/fa";
import { getmine } from "../../network/api/actions";
import { addaction } from "../../network/api/leads";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AddAction({ data, id, setShowEdit }) {
  const [date, setDate] = useState(new Date());
  const [comment, setComment] = useState("");
  const [action, setAction] = useState(false);
  const [actionSelected, setActionSelected] = useState("");
  const [actions, setActions] = useState([]);
  const [stage, setsStage] = useState(true);

  const handleAction = (value) => {
    setAction(false);
    setActionSelected(value);
    if (value.description === "No Follow") {
      setsStage(false);
    } else {
      setsStage(true);
    }
  };

  const handleaddaction = () => {
    addaction({
      id: data[id]._id,
      action: actionSelected._id,
      actiontime: date.toISOString(),
      comment,
    }).then((result) => {
      console.log(result.data);
    });
    setShowEdit(false);
  };

  useEffect(() => {
    getmine().then((result) => {
      setActions(result.data.response);
    });
  }, []);

  return (
    <div className="add-action">
      <div className="show-lead row gap-3">
      <div className="item-edit d-flex col-5 align-items-center">
          <span>
            <BiSolidUser />
          </span>
          <p>{data[id]?.name}</p>
        </div>
        <div className="item-edit d-flex col-6 align-items-center">
          <span>
            <BiPhoneCall />
          </span>
          <p>{data[id]?.mobile}</p>
        </div>
        {data[id]?.project !== "" && (
          <div className="item-edit d-flex col-5 align-items-center">
            <span>
              <BiSolidHome />
            </span>
            <p>{data[id]?.project}</p>
          </div>
        )}
        {data[id]?.channel !== "" && (
          <div className="item-edit d-flex col-4">
            <span>
              <FaFan />
            </span>
            <p>{data[id]?.channel}</p>
          </div>
        )}
      </div>
      <div className="action mt-5 px-4">
      <div className="d-flex flex-column align-items-start">
          <p className="fs-4 mb-2">Next Action</p>
          <div
            className="drop d-flex justify-content-between"
            onClick={() => setAction(!action)}
          >
            <p>{actionSelected.name}</p>
            <span>
              <BsChevronDown />
            </span>
            {action && (
              <ul>
                {actions?.map((oneaction, index) => (
                  <li onClick={() => handleAction(oneaction)}>
                    {oneaction.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        {stage && (
          <div className="d-flex flex-column align-items-start mt-4">
            <p className="fs-4 mb-2">Stage Date</p>
            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)}
              showTimeSelect
              dateFormat="Pp"
            />
          </div>
        )}
        <div className="d-flex flex-column align-items-start mt-4">
          <p className="fs-4 mb-2">Comment</p>
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
        </div>
      </div>
      <button onClick={handleaddaction} className="save">
        Save
      </button>
    </div>
  );
}
export default AddAction;
