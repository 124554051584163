import React, { useState, useEffect } from 'react';
import { getMarketers } from '../../network/api/freelance';

function MarketingCounter() {
  const [pageTable, setPageTable] = useState(1)

  const [data, setdata] = useState([]);
  const handleDecreasePageTable = () => {
    if (pageTable > 1) {
      setPageTable(pageTable - 1)
    }
  }
  const handleIncreasePageTable = () => {
    if (pageTable < Math.ceil(list.length / 12))
      setPageTable(pageTable + 1)
  }
  useEffect(() => {
    getMarketers().then(result => {
      setdata(result.data.response)
    })
  }, [])
  return (
    <>
      <div className='data-table'>
        <h1>Marketing Counts</h1>
        <div className='w-100 d-flex flex-column align-items-end ps-4'>
          <div className='table overflow-x-scroll w-100'>
            <table style={{ borderCollapse: 'collapse', borderBottom: '1px solid black' }}>
              <thead>
                <tr className=''>
                  <th>name</th>
                  <th>phone</th>
                  <th>email</th>
                  <th>count</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  if (index >= ((pageTable - 1) * 12) && index < pageTable * 12) {
                    return (
                      <tr
                        key={item.Id}
                        id={`row${item._id}`}
                      >
                        <td>{item.name}</td>
                         <td>{item.phone}</td> 
                        <td>{item.email}</td>
                        <td>{item.leadCount}</td>
                      </tr>
                    )
                  }
                })}
              </tbody>
            </table>
          </div>
          <div className='filter py-2 px-5 '>
            <p onClick={() => setPageTable(1)}>&lt;&lt;</p>
            <p onClick={() => handleDecreasePageTable()}>&lt;</p>
           <input 
  type="number" 
  value={pageTable} 
  onChange={(e) => setPageTable(parseInt(e.target.value))}
/>

            <p onClick={() => handleIncreasePageTable()}>&gt;</p>
            <p onClick={() => setPageTable(Math.ceil(list.length / 12))}>&gt;&gt;</p>
          </div>
        </div>
      </div>
    </>
  )
}
export default MarketingCounter;

export const list = [
  { Id: 1, name: 'Yousef Mohamed', phone: '201067271096', email: 'osduii@hiohio', role: 'isohi' },
  { Id: 2, name: 'Yousef Mohamed', phone: '201067271096', email: 'osduii@hiohio', role: 'isohi' },
  { Id: 3, name: 'Yousef Mohamed', phone: '201067271096', email: 'osduii@hiohio', role: 'isohi' },
  { Id: 4, name: 'Yousef Mohamed', phone: '201067271096', email: 'osduii@hiohio', role: 'isohi' },
  { Id: 5, name: 'Yousef Mohamed', phone: '201067271096', email: 'osduii@hiohio', role: 'isohi' },
  { Id: 6, name: 'Yousef Mohamed', phone: '201067271096', email: 'osduii@hiohio', role: 'isohi' },
 
]
