import axios from "../axios";
import { Cookies } from "react-cookie";
const endPoint = `/lead/`

const cookie = new Cookies();

export const getall = async () => {
    const token=cookie.get("crm")
    const res = await axios.get(`${endPoint}all`,{headers:{Authorization:token}});
    return res
};

export const createbulk = async ({data,fresh,file}) => {
    const token=cookie.get("crm")
    const formData=new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append("fresh",fresh)
    formData.append("file",file)
    const res = await axios.post(`${endPoint}createbulk`,formData,{headers:{Authorization:token}});
    return res
};

export const getfresh = async () => {
    const token=cookie.get("crm")
    const res = await axios.get(`${endPoint}fresh`,{headers:{Authorization:token}});
    return res
};

export const assign = async ({leads,broker,brokername}) => {
    const token=cookie.get("crm")
    const res = await axios.post(`${endPoint}assign`,{leads,broker,brokername},{headers:{Authorization:token}});
    return res
};
export const selfassign = async ({leads}) => {
    const token=cookie.get("crm")
    const res = await axios.post(`${endPoint}selfassign`,{leads},{headers:{Authorization:token}});
    return res
};

export const addaction = async ({id,action,actiontime,comment}) => {
    const token=cookie.get("crm")
    const res = await axios.put(`${endPoint}action/${id}`,{action,actiontime,comment},{headers:{Authorization:token}});
    return res
};

export const getallwithaction = async () => {
    const token=cookie.get("crm")
    const res = await axios.get(`${endPoint}action`,{headers:{Authorization:token}});
    return res
};

export const getfiles = async () => {
    const token=cookie.get("crm")
    const res = await axios.get(`${endPoint}files`,{headers:{Authorization:token}});
    return res
};
export const createsingle = async (data) => {
    console.log(data)
    const token=cookie.get("crm")
    const res = await axios.post(`${endPoint}createone`,data,{headers:{Authorization:token}});
    return res
};

export const getalldelay = async () => {
    const token=cookie.get("crm")
    const res = await axios.get(`${endPoint}delay`,{headers:{Authorization:token}});
    return res
};

export const getfollowaction = async () => {
    const token=cookie.get("crm")
    const res = await axios.get(`${endPoint}follow`,{headers:{Authorization:token}});
    return res
};
export const getdoneaction = async () => {
    const token=cookie.get("crm")
    const res = await axios.get(`${endPoint}done`,{headers:{Authorization:token}});
    return res
};
export const nofollowaction = async () => {
    const token=cookie.get("crm")
    const res = await axios.get(`${endPoint}no-follow`,{headers:{Authorization:token}});
    return res
};
export const assiciatedCreation = async () => {
    const token=cookie.get("crm")
    const res = await axios.get(`${endPoint}associatedcreation`,{headers:{Authorization:token}});
    return res
};
export const marketing = async () => {
    const token=cookie.get("crm")
    const res = await axios.get(`${endPoint}marketing`,{headers:{Authorization:token}});
    return res
};

export const assignMass = async ({leads,brokers}) => {
    const token=cookie.get("crm")
    const res = await axios.post(`${endPoint}assign/v2`,{leads,brokers},{headers:{Authorization:token}});
    return res
};