import { useEffect, useState } from "react";
import { createaction, getmine } from "../../network/api/actions";

function AddAction() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("Follow");
  const [data, setData] = useState([]);
  const [pageTable, setPageTable] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    load();
  }, []);
  const load = () => {
    getmine().then((result) => {
      setData(result.data.response);
    });
  };

  const handleDecreasePageTable = () => {
    if (pageTable > 1) {
      setPageTable(pageTable - 1);
    }
  };
  const handleIncreasePageTable = () => {
    if (pageTable < Math.ceil(data.length / 12)) setPageTable(pageTable + 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    createaction({ name, description }).then((result) => {
      setName("");
      setDescription("");
      load();
    });
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };
  return (
    <>
      <div className="settings mb-0 ">
        {showAlert && (
          <div className="alert-done position-fixed px-4 py-3 ">
            Well Done !!
          </div>
        )}

        <div className="add-action mb-0">
          <h1>Add Action</h1>
          <form onSubmit={(e) => handleSubmit(e)}>
            <input
              type="text"
              name="name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter The Action Name ......"
            />
            <select
              name="description"
              value={description || "Follow"} // Setting 'Follow' as the default value
              onChange={(e) => setDescription(e.target.value)}
            >
              <option value="Follow">Follow</option>
              <option value="No Follow">No Follow</option>
              <option value="Done">Done</option>
            </select>

            <div className="submit">
              <input type="submit" value="Save" />
            </div>
          </form>
        </div>
      </div>
      <div className="data-table m-0 p-0 ">
        <h1>Actions Table</h1>
        <div className="w-100 d-flex flex-column  ps-4">
          <table
            style={{
              borderCollapse: "collapse",
              borderBottom: "1px solid black",
            }}
          >
            <thead>
              <tr className="">
                <th> Action Name</th>
                <th> Type</th>
                <th>Created on </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                if (index >= (pageTable - 1) * 12 && index < pageTable * 12) {
                  return (
                    <tr key={item.id} id={`row${item.id}`}>
                      <td>{item.name}</td>
                      <td>{item.description}</td>
                      <td>{item.createdAt?.slice(0, 10)}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
          <div className="filter py-2 px-5 ">
            <p onClick={() => setPageTable(1)}>&lt;&lt;</p>
            <p onClick={() => handleDecreasePageTable()}>&lt;</p>
           <input 
  type="number" 
  value={pageTable} 
  onChange={(e) => setPageTable(parseInt(e.target.value))}
/>

            <p onClick={() => handleIncreasePageTable()}>&gt;</p>
            <p onClick={() => setPageTable(Math.ceil(data?.length / 12))}>
              &gt;&gt;
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddAction;
