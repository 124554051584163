import WhatsIcon from "../../images/whatsapp.svg";
import CallIcon from "../../images/call.svg";
import { useState } from "react";

const LeadsTable = ({
  data,
  pageTable,
  selectedRows,
  handleRowCheckboxChange,
  handleEditRow,
  vAction,
}) => {
  const [searchQuery, setSearchQuery] = useState(null);
  const [filteredData, setFilteredData] = useState(null);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    
    if (query === "") {
      setFilteredData(null);
      return;
    }
  
    const filtered = data.filter((item) =>
      item?.name?.toLowerCase().includes(query) ||
      item?.project?.toLowerCase().includes(query) || // Filter by project
      item?.destination?.toLowerCase().includes(query) // Filter by destination
    );
    
    setFilteredData(filtered);
  };

  const filteredTableData = filteredData || data;

  const handleRowHover = (value) => {
    const whatsIcon = document.querySelector(`#whatsIcon${value}`);
    const callIcon = document.querySelector(`#callIcon${value}`);
    data?.map((item) => {
      if (item?._id === value) {
        whatsIcon.classList.add("img-whats");
        callIcon.classList.add("img-call");
      }
    });
  };

  const handleRowleave = (value) => {
    const whatsIcon = document.querySelector(`#whatsIcon${value}`);
    const callIcon = document.querySelector(`#callIcon${value}`);

    data?.map((item) => {
      if (item?._id === value) {
        whatsIcon.classList.remove("img-whats");
        callIcon.classList.remove("img-call");
      }
    });
  };

  return (
    <table
      style={{ borderCollapse: "collapse", borderBottom: "1px solid black" }}
    >
      <thead>
        <tr className="">
          <th></th>
          <th>
            <input
              type="text"
              placeholder="Name,Project,Destination"
              value={searchQuery}
              onChange={handleSearch}
            />
          </th>
          <th>phone</th>
          <th>channel</th>
          <th>Project</th>
          <th>Destination</th>
          <th>Broker</th>
          <th>Comments</th>
          <th>info</th>
          {vAction && (
            <>
              {" "}
              <th>Action</th>
              <th>Action time</th>
            </>
          )}
          <th>created by</th>
          <th>time</th>
        </tr>
      </thead>
      <tbody>
        {filteredTableData?.map((item, index) => {
          if (index >= (pageTable - 1) * 12 && index < pageTable * 12) {
            return (
              <tr
                key={item?._id}
                id={`row${item?._id}`}
                onMouseOver={() => handleRowHover(item?._id)}
                onMouseLeave={() => handleRowleave(item?._id)}
              >
                <td>
                  <input
                    id="check"
                    type="checkbox"
                    checked={selectedRows.includes(item?._id)}
                    onChange={() => handleRowCheckboxChange(item?._id)}
                  />
                </td>
                <td>{item?.name}</td>
                <td className="phone d-flex justify-content-center align-items-center">
                  <span id={`phone${item?._id}`}>{item?.mobile}</span>
                  <a
                    href={`https://wa.me/+2${item?.mobile}`}
                    target="_blank"
                    className="whats-icon d-flex mx-1"
                  >
                    <img
                      id={`whatsIcon${item?._id}`}
                      src={WhatsIcon}
                      style={{ display: "none" }}
                    />
                  </a>
                  {/* how call */}
                  <a
                    href={`https://wa.me/${item?.mobile}`}
                    target="_blank"
                    className="call-icon d-flex mx-1"
                  >
                    <img
                      id={`callIcon${item?._id}`}
                      src={CallIcon}
                      style={{ display: "none" }}
                    />
                  </a>
                  <button onClick={() => handleEditRow(index)}>
                    Add Action
                  </button>
                </td>
                <td>{item?.channel}</td>
                <td>{item?.project}</td>
                <td>{item?.destination}</td>
                <td>{item?.brokername}</td>
                <td>{item?.comment}</td>
                <td>{item?.info}</td>
                {vAction && (
                  <>
                    <td class="hover-cell">
                      {item?.actions?.action?.name}
                      <div class="hidden-content">
                        {item?.actions?.action?.description}
                      </div>
                    </td>
                    <td>
                      {item?.actions?.time?.split("T")[0] +
                        " " +
                        item?.actions?.time?.split("T")[1].slice(0, 5)}
                    </td>
                  </>
                )}
                <td>{item?.createdbyname}</td>
                <td>{item?.createdAt?.slice(0, 10)}</td>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );
};

export default LeadsTable;
