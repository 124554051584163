import axios from "../axios";
import { Cookies } from "react-cookie";
const endPoint = `/login/`

const cookie = new Cookies();

export const loginapi = async (email, password) => {
    const res = await axios.post(`${endPoint}`, { email, password });
    if (res.data.token) {
        await cookie.set("crm", "Bearer " + res.data.token, { path: "/" });
        return { success: true, freelancer: res.data.freelancer, info: res.data.info, message: res.data.message };
    } else return { success: false, message: res.data.message };
};