
import axios from "../axios";
import { Cookies } from "react-cookie";
const endPoint = `/company/`

const cookie = new Cookies();

export const signupapi = async (signupForm) => {
    return await axios.post(`${endPoint}signup`, signupForm);
};

export const getMyProfile = async () => {
    const token = cookie.get("crm")
    return await axios.get(`${endPoint}my_profile`, {
        headers: { Authorization: token },
    });
};

export const apply = async (id) => {
    const token = cookie.get("crm")
    return await axios.get(`${endPoint}join/${id}`,  {
        headers: { Authorization: token },
    });
};


export const requests = async (id) => {
    const token = cookie.get("crm")
    return await axios.get(`${endPoint}requests`,  {
        headers: { Authorization: token },
    });
};
export const deleterequest = async (id) => {
    const token = cookie.get("crm")
    return await axios.put(`${endPoint}deleterequest`,{id:id},  {
        headers: { Authorization: token },
    });
};
export const acceptrequest = async (id,role) => {
    const token = cookie.get("crm")
    return await axios.put(`${endPoint}acceptrequest`,{id:id,role:role},  {
        headers: { Authorization: token },
    });
};