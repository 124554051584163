import React, { useEffect } from 'react';
import './App.css';
import { useState } from 'react';
import LeftSide from './components/LeftSide';
import { BrowserRouter as Router, Route, Routes,Navigate  } from 'react-router-dom';
import ImportData from './components/rightside/ImportLeads';
import DataTablePage from './components/rightside/DataTablePage';
import SelectOptions from './components/rightside/SelectedOptions';
import Navbar from './components/rightside/Navbar';
import Image from './images/man.svg'
import './components/rightSide.css'
import AssociatedCreations from './components/rightside/AssociatedCreations';
import Marketing from './components/rightside/Marketing';
import FreashLeads from './components/rightside/Freashleads';
import AllLeadsC from './components/rightside/AllLeadsC';
import ActionLeads from './components/rightside/ActionLeads';
import DelayLeads from './components/rightside/DelayLeads';
import AddColumnHeader from './components/settings/AddColumnHeader';
import AddChannel from './components/settings/AddChannel';
import AddProject from './components/settings/AddProject';
import AddAction from './components/settings/AddAction';
import JoinCompany from './components/settings/JoinCompany';
import AddUser from './components/users/AddUser';
import ShowUsers from './components/users/Showusers';
import MarketingCounter from './components/users/MarketingCounter';
import Login from './components/Login/Login'
import PrivateRoute from './components/PrivateRoute';
import { Cookies } from 'react-cookie';
import JoinRequests from './components/users/JoinRequests';
import UploadedFiles from './components/settings/UploadedFiles';
import AddSingleLead from './components/rightside/AddSingleLead';
import FollowLeads from './components/rightside/FollowLeads';
import DoneLeads from './components/rightside/DoneLeads';
import NoFollowLeads from './components/rightside/NoFollowLeads';

 function App() {
  const cookie=new Cookies()
   const [page, setPage] = useState('Home')
   const [info, setinfo] = useState({})
   const [path, setPath] = useState('/')
   const [auth, setauth] = useState('false')
   const [showSide, setShowSide] = useState(false);
   useEffect(()=>{
    let c=cookie.get("crm")
    setauth(c!==undefined)
   },[auth])

   return (
      <Router>
        
      <div className="App"> 
        <div className='d-flex'>
          <div className='d-none d-xl-block'>
          {auth&&<LeftSide setpage={setPage} setpath={setPath} info={info} showSide={showSide} setShowSide={setShowSide}/>}
          </div>
          {showSide&&
          <div className='left-side-app d-xl-none'>
          {auth&&<LeftSide setpage={setPage} setpath={setPath} info={info} showSide={showSide} setShowSide={setShowSide}/>}
          </div>
          }
          <div className='right-side'>
           {auth&&<Navbar page={page} image={Image} setinfo={setinfo} info={info} showSide={showSide} setShowSide={setShowSide}/>}
      <Routes>
        <Route path="/home"  element={<Navigate to="/all-leads" />} />
        <Route path="/import-leads" element={<PrivateRoute element={ImportData}/>} />
        <Route path="/add-single" element={<PrivateRoute element={AddSingleLead}/>} />
        <Route path="/selectOptions" element={<PrivateRoute element={SelectOptions}/>} />
        <Route path="/displayData" element={<PrivateRoute element={DataTablePage} list={list}/>} />
        <Route path="/fresh-leads" element={<PrivateRoute element={FreashLeads}  list={list}/>} />
        <Route path="/all-leads-c" element={<PrivateRoute element={AllLeadsC} />} />
        <Route path="/all-leads" element={<PrivateRoute element={ActionLeads} />} />
        <Route path="/delay-leads" element={<PrivateRoute element={DelayLeads} />} />
        <Route path="/follow-leads" element={<PrivateRoute element={FollowLeads} />} />
        <Route path="/done-leads" element={<PrivateRoute element={DoneLeads} />} />
        <Route path="/no-follow-leads" element={<PrivateRoute element={NoFollowLeads} />} />
        <Route path="/add-channel" element={<PrivateRoute element={AddChannel} list={list}/>} />
        <Route path="/add-project" element={<PrivateRoute element={AddProject}  list={list}/>} />
        <Route path="/add-action" element={<PrivateRoute element={AddAction}/>}  list={list}/>
        <Route path="/associated" element={<PrivateRoute element={AssociatedCreations}/>}  list={list}/>
        <Route path="/marketing" element={<PrivateRoute element={Marketing}/>}  list={list}/>
        <Route path="/add-column-header" element={<PrivateRoute element={AddColumnHeader}  list={list}/>} />
        <Route path="/uploads" element={<PrivateRoute element={UploadedFiles}  list={list}/>} />
        <Route path="/join-company" element={<PrivateRoute element={JoinCompany}  list={list}/>} />
        <Route path="/show-users" element={<PrivateRoute element={ShowUsers}/>}  list={list}/>
        <Route path="/marketing-counter" element={<PrivateRoute element={MarketingCounter}/>}  list={list}/>
        <Route path="/add-users" element={<PrivateRoute element={AddUser}/>} />
        <Route path="/requests" element={<PrivateRoute element={JoinRequests}/>} />
        <Route path="/login" element={<Login setpage={setPage} setauth={setauth} setinfo={setinfo}/>} />
        <Route path="/" element={<Navigate to="/all-leads" />} />
      </Routes>
        </div>
        </div>
      </div>
    </Router>
  );
}

export default App;


export const list = [
  { id: 1, 'name': 'yousskef', 'phone': '01022222222', },
  { id: 2, 'name': 'Momhamed', 'phone': '0165600000', },
  { id: 3, 'name': 'youssef', 'phone': '01022222222', },
  { id: 4, 'name': 'Momhamed', 'phone': '01000000000', },
  { id: 5, 'name': 'youssef', 'phone': '01022222222', },
  { id: 6, 'name': 'Momhamed', 'phone': '01005900', },
]
