import React, { useContext, useState, useEffect } from 'react';
import Image from '../../images/man.svg';
import { getassoiciated } from '../../network/api/freelance';
import { AssignDivContext } from './AllLeadsC';

function AssignFresh() {
  const { setShowAssign, handleAssignAction, handleselfAssignAction } = useContext(AssignDivContext);
  const [users, setUsers] = useState([]);
  const [selectedBrokers, setSelectedBrokers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleAgree = () => {
    handleAssignAction({ brokers: selectedBrokers });
  };

  useEffect(() => {
    getassoiciated().then(result => {
      const sortedUsers = result.data.response.sort((a, b) => a.name.localeCompare(b.name));
      setUsers(sortedUsers);
    });
  }, []);

  // Filter the users based on the search term
  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Function to toggle selection of a broker
  const toggleBrokerSelection = (broker) => {
    const isSelected = selectedBrokers.some(selected => selected.id === broker._id);
    if (isSelected) {
      setSelectedBrokers(selectedBrokers.filter(selected => selected.id !== broker._id));
    } else {
      setSelectedBrokers([...selectedBrokers, { id: broker._id, name: broker.name }]);
    }
  };

  return (
    <div className="assign-div">
      <div>
        <div className='back'>
          <span className='' onClick={() => setShowAssign(false)}>X</span>
        </div>
        <h2 className='mb-5'>Assign Leads To :</h2>
        <div>
          <input
            type="text"
            className='d-flex flex-column align-items-start ms-4 '
            placeholder="Search by name"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          /> 
        </div>
        <div className="people">
          <ul>
            {filteredUsers.map(item => (
              <li className='d-flex align-items-start' key={item._id} onClick={() => toggleBrokerSelection(item)}>
                <input
                  type="checkbox"
                  checked={selectedBrokers.some(selected => selected.id === item._id)}
                  onChange={() => {}}
                />
                <img src={Image} width={50} height={50} className='pt-1' alt="User" />
                <div className='d-flex flex-column align-items-start ms-4 '>
                  <p className='m-0 name'>{item.name} </p>
                  <p className='title'>{item.role}</p>
                  <p className='title'>{item.phone}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className='d-flex flex-row-reverse justify-content-between align-items-end mt-2 ms-4'>
          <button className='agree align-self-start float-right' onClick={() => { handleselfAssignAction() }}>selfassign</button>
          {selectedBrokers.length > 0 ?
            <button className='agree align-self-start float-right' onClick={handleAgree}>Agree</button>
            :
            <button className='no-agree align-self-start float-right' disabled>Agree</button>
          }
        </div>
      </div>
    </div>
  )
}

export default AssignFresh;
