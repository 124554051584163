import React from 'react';
import { Navigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';

const PrivateRoute = ({ element: Component }) => {
  const cookie = new Cookies();
  let auth = cookie.get('crm');
  if(auth?.length<15)
      { 
        auth=undefined
      }
  return auth ? <Component /> : <Navigate to="/login" />;
};

export default PrivateRoute;
