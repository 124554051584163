import { useState } from 'react'
import WhatsIcon from '../../images/whatsapp.svg'
import CallIcon from '../../images/call.svg'

function AddColumnHeader({ list }) {
  const [name, setName] = useState();
  const [description, setDescription] = useState();

  const [data, setData] = useState(list);
  const [phoneStr, setphoneStr] = useState()
  const [pageTable, setPageTable] = useState(1)



  const handleDecreasePageTable = () => {
    if (pageTable > 1) {
      setPageTable(pageTable - 1)
    }
  }

  const handleIncreasePageTable = () => {
    if (pageTable < Math.ceil(data.length / 12))
      setPageTable(pageTable + 1)
  }

  return (
    <>
      <div className="settings">
        <div className='add-column-header'>
          <h1>Add Column Header</h1>
          <form onSubmit={(e) => ''}>
            <input
              type='text'
              name='name'
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='Enter The Name ......'
            />

            <input
              type='text'
              name='description'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder='Enter The Description ......'
            />
            <div className='submit'>
              <input type='submit' value='Save' />
            </div>
          </form>
        </div>
      </div>
      <div className='data-table m-0 p-0 ' >
        <h1>Columns Header Table</h1>
        <div className='w-100 d-flex flex-column align-items-end ps-4'>
          
          <table style={{ borderCollapse: 'collapse', width: '100%', borderBottom: '1px solid black' }}>
            <thead>
              <tr className=''>
                <th></th>
                <th>Name</th>
                <th>Phone</th>
                <th>Chanal</th>
                <th>Project</th>
                <th>Salesman</th>
                <th>Comments</th>
                <th>Job</th>
                <th>Created by</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                if (index >= ((pageTable - 1) * 12) && index < pageTable * 12) {
                  return (
                    <tr
                      key={item.id}
                      id={`row${item.id}`}
                    >
                      
                      <td >{item.name}</td>
                      <td className='phone d-flex justify-content-center align-items-center'>
                        <span id={`phone${item.id}`}>{phoneStr}</span>
                        <a href={`https://wa.me/+2${item.phone}`} target='_blank' className='whats-icon d-flex mx-1'>
                          <img id={`whatsIcon${item.id}`} src={WhatsIcon} style={{ display: 'none' }} />
                        </a>
                        {/* how call */}
                        <a href={`https://wa.me/${item.phone}`} target='_blank' className='call-icon d-flex mx-1'>
                          <img id={`callIcon${item.id}`} src={CallIcon} style={{ display: 'none' }} />
                        </a>
                        <button >Add Action</button>
                      </td>
                      <td>idgaioeop</td>
                      <td>Some comments</td>
                      <td>Some job</td>
                      <td>gewyu</td>
                      <td>oefvn</td>
                      <td>oefvn</td>
                      <td>oefvn</td>
                    </tr>
                  )
                }
              })}
            </tbody>
          </table>
          <div className='filter py-2 px-5 '>
            <p onClick={() => setPageTable(1)}>&lt;&lt;</p>
            <p onClick={() => handleDecreasePageTable()}>&lt;</p>
           <input 
  type="number" 
  value={pageTable} 
  onChange={(e) => setPageTable(parseInt(e.target.value))}
/>

            <p onClick={() => handleIncreasePageTable()}>&gt;</p>
            <p onClick={() => setPageTable(Math.ceil(data?.length / 12))}>&gt;&gt;</p>
          </div>
        </div>
      </div>
    </>
  )
}
export default AddColumnHeader