
import axios from "../axios";
import { Cookies } from "react-cookie";
const endPoint = `/freelancer/`

const cookie = new Cookies();

export const signupapi = async (signupForm) => {
    return await axios.post(`${endPoint}signup`, signupForm);
};

export const getassoiciated = async () => {
    const token = cookie.get("crm")
    return await axios.get(`${endPoint}associated`,{headers:{Authorization:token}});
};
export const createAssociate = async (signupForm) => {
    const token = cookie.get("crm")
    return await axios.post(`${endPoint}create`, signupForm,{headers:{Authorization:token}});
};

export const getroles = async () => {
    const token = cookie.get("crm")
    return await axios.get(`${endPoint}roles`,{headers:{Authorization:token}});
};

export const getMarketers = async () => {
    const token = cookie.get("crm")
    return await axios.get(`${endPoint}marketeres`,{headers:{Authorization:token}});
};
export const getAssignements = async (id) => {
    const token = cookie.get("crm")
    return await axios.get(`${endPoint}assignements/${id}`,{headers:{Authorization:token}});
};