import { useState,useEffect } from 'react'
import { createproject,getmine } from '../../network/api/projects';

function AddProject() {
  const [name, setName] = useState();
  const [region, setRegion] = useState();
  const [regions, setregions] = useState([]);
  const [showAlert, setShowAlert] = useState(false)

  const [data, setData] = useState([]);

  const [pageTable, setPageTable] = useState(1)


  const handleDecreasePageTable = () => {
    if (pageTable > 1) {
      setPageTable(pageTable - 1)
    }
  }
  const handleIncreasePageTable = () => {
    if (pageTable < Math.ceil(data.length / 12))
      setPageTable(pageTable + 1)
  }

  const load=()=>{
    getmine().then(result=>{
      setData(result.data.response)
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    createproject({ name, region }).then(result => {
      setName("")
      load()
    })

    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
    }, 2000);
  }
  const handleRoleChange = (event) => {
    setRegion(event.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://crm.3aqaar.com/location');
        const data = await response.json();
        console.log(data)
        setregions(data.response.response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

     load()
  }, []); 
  
  return (
    <>
      <div className="settings">
        {showAlert &&
          <div className='alert-done position-fixed px-4 py-3 '>Well Done !!</div>
        }
        <div className='add-project'>
          <h1>Add Project</h1>
          <form onSubmit={(e) => handleSubmit(e)}>
            <input
              type='text'
              name='name'
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='Enter The Project Name ......'
            />
            <select

            id="mySelect"
            onChange={handleRoleChange}
            value={region}
            style={{
              outline: "none",
              padding: "5px 10px",
              margin: "15px 0",
              fontSize: "20px",
              fontWeight: "600",
              borderRadius: "8px",
              border: "1px solid #4c4c4c",
              background: "#ffffff",
            }}
          >
            <option value={""}>select a region</option>
            {regions.map((singleregion,index)=>(
            <option value={singleregion.name}>{singleregion.name===""?"select a role":singleregion.name}</option>

            ))}
          </select>
            <div className='submit'>
              <input type='submit' value='Save' />
            </div>
          </form>
        </div>
      </div>
      <div className='data-table m-0 p-0' >
        <h1>Projects Table</h1>
        <div className='w-100 d-flex flex-column align-items-end ps-4'>
          <div className='table overflow-x-scroll w-100'>
          <table style={{ borderCollapse: 'collapse', borderBottom: '1px solid black' }}>
              <thead>
                <tr className=''>
                  <th></th>
                  <th> Project Name</th>
                  <th>Region</th>
                  <th>Created on </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => {
                  if (index >= ((pageTable - 1) * 12) && index < pageTable * 12) {
                    return (
                      <tr
                        key={item.id}
                        id={`row${item.id}`}
                        
                      >
                         <td ></td>
                        <td >{item.name}</td>
                        <td >{item.region}</td>
                        <td>{item.createdAt?.slice(0,10)}</td>

                        
                      </tr>
                    )
                  }
                })}
              </tbody>
            </table>
          </div>
          <div className='filter py-2 px-5 '>
            <p onClick={() => setPageTable(1)}>&lt;&lt;</p>
            <p onClick={() => handleDecreasePageTable()}>&lt;</p>
            <p>{pageTable}</p>
            <p onClick={() => handleIncreasePageTable()}>&gt;</p>
            <p onClick={() => setPageTable(Math.ceil(data?.length / 12))}>&gt;&gt;</p>
          </div>
        </div>
      </div>
    </>
  )
}
export default AddProject;