import { useState } from 'react'
import { apply} from '../../network/api/company';

function JoinCompany() {
  const [Code, setCode] = useState();


  const handleSubmit = (e) => {
    e.preventDefault()

    apply(Code).then(result=>{console.log(result.data)})
  }


  return (
    <>
      <div classCode="settings">
        <div classCode='add-project'>
          <h1>Join Company</h1>
          <form onSubmit={(e) => handleSubmit(e)}>
            <input
              type='text'
              name='Code'
              required
              value={Code}
              onChange={(e) => setCode(e.target.value)}
              placeholder='Enter The Invitation  Code ......'
            />
           
            <div className='submit'>
              <input type='submit' value='Save' />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export default JoinCompany;