import { useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { MdAdd } from 'react-icons/md';
import AddAction from './AddAction';
import EditLead from './EditLead';

function EditItem({ data, id, setShowEdit }) {
  const [activeEdit, setActiveEdit] = useState(false);

  return (
    <div className="edit-item">
      <div className="edit-row pt-4">
        <div className="edit-btns">
          <div className='d-flex align-items-center'>
            <div className="cancel me-4 text-white" style={{ background: '#05beae', cursor: 'pointer' }}
              onClick={() => setShowEdit(false)}><span>x</span></div>
            <p className='m-0 fs-2' style={{ fontWeight: '600' }}>
              {activeEdit ? 'Edit Lead' : 'Add Action'}
            </p>
          </div>
          <div className="btns">
            <div className={`add ${!activeEdit && 'active'}`}
              onClick={() => setActiveEdit(false)}
            ><MdAdd /></div>
            <div className={`edit-btn ${activeEdit && 'active'}`}
              onClick={() => setActiveEdit(true)}
            ><BiEdit /></div>
          </div>
        </div>
        {!activeEdit && <AddAction data={data} id={id} setShowEdit={setShowEdit} />}
        {activeEdit && <EditLead data={data} id={id} setShowEdit={setShowEdit} />}
      </div>
    </div>
  )
}
export default EditItem;