import React, { createContext, useState } from 'react';
import { Link, useLocation,useNavigate } from 'react-router-dom';
import AssignFresh from './AssignFresh';
import { createbulk } from '../../network/api/leads';

export const AssignDivContext = createContext();
function DataTablePage({list}) {
  const navigate=useNavigate()
  const [showAssign, setShowAssign] = useState(false);
  const owner = "omar";
  const location = useLocation();
  const {
    state: {
      importedData,
      selectedOption1,
      selectedOption2,
      selectedOption3,
      selectedChanal,
      selectedProject,
      selectedSalesman,
      currentTime,
      fresh,
      file
    },
  } = location;

  const [selectedRows, setSelectedRows] = useState([]);
  const [editingRow, setEditingRow] = useState(-1);
  const [data, setData] = useState(list);
  const handleRowCheckboxChange = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((row) => row !== rowIndex));
      setEditingRow(-1);
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
      setEditingRow(-1);
    }
  };

  const handleEditClick = (rowIndex) => {
    setEditingRow(rowIndex);
  };
  const handleSaveClick = () => {
    console.log(importedData)
    createbulk({data:importedData,fresh:fresh,file:file}).then(result=>{
      navigate('/all-leads')
    })
  };

  

  return (
    <>
      <AssignDivContext.Provider value={{ showAssign, setShowAssign }}>
        <div className='data-table'>
          <h1>Data Table</h1>
          {/* <div className='d-flex justify-content-end w-100'>
            {selectedRows.length >= 1 ? (
              <button onClick={handleAssignClick} className='assign' >Assign</button>
            ) :
              <button className='no-assign'>Assign</button>
            }
          </div> */}
          <table style={{ borderCollapse: 'collapse', width: '85%', borderBottom: '1px solid black' }}>
            <thead>
              <tr className=''>
                <th style={{ borderBottom: '1px solid black', paddingTop: '8px', paddingBottom: '8px' }}>
                  {selectedRows.length === 1 ? (
                    <button onClick={() => handleEditClick(selectedRows[0])} className='edit-btn'>Edit</button>
                  ) : null}
                </th>
                {selectedOption1 && <th style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>name</th>}
                {selectedOption2 && <th style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>mobile</th>}
                {selectedOption3 && <th style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>destination</th>}
                <th style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>channel</th>
                <th style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>Project</th>
                <th style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>Salesman</th>
                <th style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>comments</th>
                <th style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>job</th>
                <th style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>created by</th>
                <th style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>time</th>
              </tr>
            </thead>
            <tbody>
              {importedData &&
                importedData.map((row, index) => (
                  <tr key={index} style={{ backgroundColor: 'white' }}>
                    <td style={{ borderBottom: '1px solid black', textAlign: 'center', paddingTop: '8px', paddingBottom: '8px' }}>
                      <input
                        id='check'
                        type="checkbox"
                        checked={selectedRows.includes(index)}
                        onChange={() => handleRowCheckboxChange(index)}
                      />
                    </td>
                    {editingRow === index ? (
                      <>
                        <td>
                          <input
                            type="text"
                            value={row[selectedOption1] || ''}
                            onChange={(e) => {
                              const updatedData = [...data];
                              updatedData[index][selectedOption1] = e.target.value;
                              setData(updatedData);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={row[selectedOption2] || ''}
                            onChange={(e) => {
                              const updatedData = [...data];
                              updatedData[index][selectedOption2] = e.target.value;
                              setData(updatedData);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={row[selectedOption3] || ''}
                            onChange={(e) => {
                              const updatedData = [...data];
                              updatedData[index][selectedOption3] = e.target.value;
                              setData(updatedData);
                            }}
                          />
                        </td>
                      </>
                    ) : (
                      <>
                        {selectedOption1 && (
                          <td style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>
                            {row[selectedOption1]}
                          </td>
                        )}
                        {selectedOption2 && (
                          <td style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>
                            {row[selectedOption2]}
                          </td>
                        )}
                        {selectedOption3 && (
                          <td style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>
                            {row[selectedOption3]}
                          </td>
                        )}
                      </>
                    )}
                    <td style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>{selectedChanal}</td>
                    <td style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>{selectedProject}</td>
                    <td style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>{selectedSalesman}</td>
                    <td style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>Some comments</td>
                    <td style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>Some job</td>
                    <td style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>{owner}</td>
                    <td style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>
                      {currentTime.toLocaleString()}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className='d-flex justify-content-end w-100'>
            <button onClick={handleSaveClick} className='link-btn' style={{ marginRight: '120px' }}>Save All</button>
          </div>
        </div>
        {showAssign && <AssignFresh rownum={selectedRows.length} />}
      </AssignDivContext.Provider>
    </>
  )
}
export default DataTablePage;