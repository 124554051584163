import React, { createContext, useEffect, useState } from 'react';
import Assign from './AssignMarket';
import EditItem from './EditItem';
import { marketing, assign, selfassign } from '../../network/api/leads';
import LeadsTable from './LeadsTable';

export const AssignDivContext = createContext()

function AssociatedCreations() {
  const [data, setData] = useState([]);
  const [showAssign, setShowAssign] = useState(false)
  const [pageTable, setPageTable] = useState(1)
  const [selectedRows, setSelectedRows] = useState([]);
  const [editingRow, setEditingRow] = useState(-1);
  const [showEdit, setShowEdit] = useState(false);
  const [idOfRow, setIdOfRow] = useState(0)

  const handleRowCheckboxChange = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((row) => row !== id));
      setEditingRow(-1);
    } else {
      setSelectedRows([...selectedRows, id]);
      setEditingRow(-1);
    }
  };

  const handleAssignClick = () => {
    setShowAssign(true)
  }

  const handleAssignAction = ({ broker, brokername }) => {
    const leads = selectedRows
    
    assign({ leads: leads, broker: broker, brokername: brokername }).then(result => {
      if (result.data.success === true)
      setSelectedRows([])
      setShowAssign(false)
    })
  }

  const handleselfAssignAction = () => {
    const leads =selectedRows
    selfassign({ leads: leads }).then(result => {
      if (result.data.success === true)
      setSelectedRows([])
      setShowAssign(false)
    })
  }

  useEffect(() => {
    marketing().then(result => {
      setData(result.data.response)
    })
  }, []);
  
  const handleEditRow = (value) => {
    setShowEdit(true);
    setIdOfRow(value)
  }
  const handleDecreasePageTable = () => {
    if (pageTable > 1) {
      setPageTable(pageTable - 1)
    }
  }
  const handleIncreasePageTable = () => {
    if (pageTable < Math.ceil(data.length / 12))
      setPageTable(pageTable + 1)
  }

  return (
    <>
      <AssignDivContext.Provider value={{ showAssign, setShowAssign, handleAssignAction, handleselfAssignAction }}>
        <div className='data-table'>
          <h1>Users added leads</h1>
          <div className='w-100 d-flex flex-column align-items-end ps-4'>
            <div className='d-flex justify-content-end w-100'>
              {selectedRows.length >= 1 ? (
                <button onClick={handleAssignClick} className='assign' >Assign</button>
              ) :
                <button className='no-assign'>Assign</button>
              }
            </div>
            <div className='table overflow-x-scroll w-100'>
            <LeadsTable data={data}pageTable={pageTable}selectedRows={selectedRows}handleRowCheckboxChange={handleRowCheckboxChange}handleEditRow={handleEditRow}vAction={false} />
            </div>
            <div className='filter py-2 px-5 '>
              <p onClick={() => setPageTable(1)}>&lt;&lt;</p>
              <p onClick={() => handleDecreasePageTable()}>&lt;</p>
             <input 
  type="number" 
  value={pageTable} 
  onChange={(e) => setPageTable(parseInt(e.target.value))}
/>

              <p onClick={() => handleIncreasePageTable()}>&gt;</p>
              <p onClick={() => setPageTable(Math.ceil(data.length / 12))}>&gt;&gt;</p>
            </div>
          </div>
        </div>
        {showAssign && <Assign />}
        {showEdit && <EditItem  data={data} id={idOfRow} setShowEdit={setShowEdit} />}
      </AssignDivContext.Provider>
    </>
  )
}
export default AssociatedCreations;