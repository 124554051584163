import { useEffect, useState } from "react";
import { createAssociate, getroles } from "../../network/api/freelance";
function AddUser() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [role, setRole] = useState("");
  const [password, setPassword] = useState();
  const [yearsOfExp, setyearsOfExp] = useState();
  const [roles, setRoles] = useState([]);
  const handleRoleChange = (event) => {
    setRole(event.target.value); // Update the state with the selected option's value
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    createAssociate({
      name: name,
      email: email,
      password: password,
      yearsOfExp: yearsOfExp,
      role: role,
    }).then((result) => {
      window.location.reload();
    });
  };
  useEffect(() => {
    getroles().then((result) => {
      setRoles(result.data.roles);
    });
  }, []);
  return (
    <div className="users">
      <div className="add-user">
        <h1>Add User</h1>
        <form onSubmit={(e) => handleSubmit(e)}>
          <input
            type="text"
            name="name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter The User Name ......"
          />
          <input
            type="text"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter The User Email ......"
          />

          <select
            id="mySelect"
            onChange={handleRoleChange}
            value={role}
            style={{
              outline: "none",
              padding: "5px 10px",
              margin: "15px 0",
              fontSize: "20px",
              fontWeight: "600",
              borderRadius: "8px",
              border: "1px solid #4c4c4c",
              background: "#ffffff",
            }}
          >
            {roles.map((onerole,index)=>(
            <option value={onerole}>{onerole===""?"select a role":onerole}</option>

            ))}
          </select>
          <input
            type="text"
            name="yearsOfExp"
            value={yearsOfExp}
            onChange={(e) => setyearsOfExp(e.target.value)}
            placeholder="Enter The User yearsOfExp ......"
          />
          <input
            type="text"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter The User Password ......"
          />
          <div className="submit">
            <input type="submit" value="Save" />
          </div>
        </form>
      </div>
    </div>
  );
}
export default AddUser;
