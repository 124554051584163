import axios from "../axios";
import { Cookies } from "react-cookie";
const endPoint = `/projects/`

const cookie = new Cookies();

export const createproject = async ({name,region}) => {
    const token=cookie.get("crm")
    const res = await axios.post(`${endPoint}createone`,{name,region},{headers:{Authorization:token}});
    return res
};

export const getmine = async () => {
    const token=cookie.get("crm")
    const res = await axios.get(`${endPoint}my`,{headers:{Authorization:token}});
    return res
};