import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import * as channelsapi from '../../network/api/channels'
import * as projectsapi from '../../network/api/projects'

function SelectOptions() {
  const location = useLocation();
  const importedData = location.state.importedData;
  const fresh = location.state.fresh;
  const file = location.state.file;
  const [selectedOption1, setSelectedOption1] = useState('');
  const [selectedOption2, setSelectedOption2] = useState('');
  const [selectedOption3, setSelectedOption3] = useState('');
  const [selectedChanal, setSelectedChanal] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedSalesman, setSelectedSalesman] = useState('');

  const [channels, setchannels] = useState([]);
  const [projects, setprojects] = useState([]);
  const navigate = useNavigate();

  const handleOptionChange1 = (e) => {
    setSelectedOption1(e.target.value);
  };

  const handleOptionChange2 = (e) => {
    setSelectedOption2(e.target.value);
  };
  const handleOptionChange3 = (e) => {
    setSelectedOption3(e.target.value);
  };
  const handleChanalChange = (e) => {
    setSelectedChanal(e.target.value);
  };

  const handleProjectChange = (e) => {
    setSelectedProject(e.target.value);

  };

  const handleSalesmanChange = (e) => {
    setSelectedSalesman(e.target.value);
  };

  const navigateToImport = () => {
    navigate('/import-leads')
  }
  const navigateToDisplayData = () => {
    const currentTime = new Date();

    for (let index = 0; index < importedData.length; index++) {
      importedData[index].name = importedData[index][selectedOption1]
      importedData[index].mobile = importedData[index][selectedOption2]
      importedData[index].destination = importedData[index][selectedOption3]
      importedData[index].channel = selectedChanal
      importedData[index].broker = selectedSalesman
      importedData[index].project = selectedProject
    }
    navigate('/displayData', {
      state: {
        importedData,
        selectedOption1,
        selectedOption2,
        selectedOption3,
        selectedChanal,
        selectedProject,
        selectedSalesman,
        currentTime,
        fresh,
        file
      },
    });
  };
  useEffect(() => {
    channelsapi.getmine().then(result => { setchannels(result.data.response) })
    projectsapi.getmine().then(result => { setprojects(result.data.response) })
  }, [])

  return (
    <div>

      <div className='d-flex flex-column flex-md-row flex-wrap justify-content-between m-5 gap-3'>
        <select required className='btn border-dark col-12 col-lg-3 text-secondary flex-grow-1 '
          style={{ textAlign: "left", fontSize: "1.2rem" }}
          value={selectedChanal} onChange={handleChanalChange}>
          <option value="" disabled selected className='text-secondary-subtle' >channel</option>
          {channels?.map((channel, index) => (
            <option value={channel.name}>{channel.name}</option>
          ))}
        </select>
        <select required className='btn border-dark col-12 col-lg-3 text-secondary flex-grow-1 '
          style={{ textAlign: "left", fontSize: "1.2rem" }}
          value={selectedProject} onChange={handleProjectChange}>
          <option value="" disabled selected className='text-secondary-subtle'>Project</option>
          {projects?.map((project, index) => (
            <option value={project.name}>{project.name}</option>
          ))}
        </select>
        <select className='btn border-dark col-12 col-lg-3  text-secondary flex-grow-1 '
          style={{ textAlign: "left", fontSize: "1.2rem" }}
          value={selectedSalesman} onChange={handleSalesmanChange}>
          <option value="" disabled selected className='text-secondary-subtle'>Salesman</option>
          <option value="Mark">mark</option>
          <option value="ola">ola</option>
        </select>
      </div>
      <table className='mx-5' style={{ borderCollapse: 'collapse', width: '90%', borderBottom: '1px solid black' }}>
        <thead>
          <tr className='' style={{ background: '#6168ee , ' }}>
            <th style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>Feild</th>
            <th style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>Excel Header</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ backgroundColor: 'white' }}>
            <td style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>
              Full name
            </td>
            <td style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>
              <select className='btn border-dark text-secondary'
                style={{ textAlign: "left", fontSize: "1.2rem" }}
                value={selectedOption1} onChange={handleOptionChange1}>
                <option value="" disabled selected className='text-secondary-subtle' >Select </option>
                {importedData.length > 0 &&
                  Object.keys(importedData[0]).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
              </select>
            </td>
          </tr>
          <tr style={{ backgroundColor: 'white' }}>
            <td style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>
              phone Number
            </td>
            <td style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>
              <select
                className='btn border-dark text-secondary'
                style={{ textAlign: "left", fontSize: "1.2rem" }} value={selectedOption2} onChange={handleOptionChange2}>
                <option value="" disabled selected className='text-secondary-subtle' >Select</option>
                {importedData.length > 0 &&
                  Object.keys(importedData[0]).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
              </select>
            </td>
          </tr>
          <tr style={{ backgroundColor: 'white' }}>
            <td style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>
             destination
            </td>
            <td style={{ borderBottom: '1px solid black', textAlign: "center", paddingTop: '8px', paddingBottom: '8px' }}>
              <select
                className='btn border-dark text-secondary'
                style={{ textAlign: "left", fontSize: "1.2rem" }} value={selectedOption3} onChange={handleOptionChange3}>
                <option value="" disabled selected className='text-secondary-subtle' >Select</option>
                {importedData.length > 0 &&
                  Object.keys(importedData[0]).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
              </select>
            </td>
          </tr>
        </tbody>
      </table>
      <div className='my-5 mx-5'>
        <button className='btn me-2' style={{ width: "10%", border: '1px solid black' }} onClick={navigateToImport}>Back</button>
        <button className='next text-light ms-4' style={{ width: "10%" }} onClick={navigateToDisplayData}>Next</button>
      </div>
    </div>
  );
}

export default SelectOptions;












