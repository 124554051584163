import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsUpload, BsDownload } from 'react-icons/bs';
import * as XLSX from 'xlsx';

function ImportData() {
  const [fresh] = useState(true)
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [file, setFile] = useState(null) // State to store the imported data
  const [showselectexel, setshowselectexel] = useState(true); // Flag to control visibility
  const [loaded, setLoaded] = useState(false)

  // Function to handle file upload
  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      console.log(new Blob([data]))
      setFile(new Blob([data]))
      const workbook = XLSX.read(data, { type: 'buffer' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      console.log(parsedData)
      setData(parsedData); // Store the parsed data in the state
      setLoaded(true)
    };
  };
  const handlesetoptionClick = () => {
    setshowselectexel(false); // Hide the Excel upload section
    navigate('/selectOptions', { state: { importedData: data, fresh: fresh,file:file } }); 
  };

  return (
    <div className='px-2 ps-lg-5'>
      {showselectexel && ( // Conditionally render the Excel upload section
        <div>
          <div className=' d-flex flex-wrap justify-content-between my-5 '>
            <div className='col-12 col-lg-6 d-flex flex-wrap'>
              {/* <div className='col-12'>
                <button className={`w-25 ${fresh ? 'bg-active' : 'bg-no-active'}`}
                  style={{ width: "17.5%" }} onClick={() => setFresh(true)}> fresh</button>
                <button className={`w-25 ${fresh ? 'bg-no-active' : 'bg-active'}`}
                  style={{ width: "17.5%" }} onClick={() => setFresh(false)}>cold</button>
              </div> */}
            </div>
            <div className=' col-12 col-lg-6 d-flex flex-wrap justify-content-center'>
              <button className='h-50 my-4  mx-2 bg-success-subtle border-1 border-success btn'
                style={{ width: "35%" }}>Download  Guide</button>
              <button className='h-50 my-4 mx-2 bg-success-subtle btn border-success border-1'
                style={{ width: "35%" }}>Download sample </button>
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center w-100'>
            <label
              style={{ height: "300px", textAlign: "center", fontSize: "3rem", padding: "100px", border: '2px solid #05beae' }}
              htmlFor="file"
              className='btn text-primary-subtle bg-light import-label'
            >
              {loaded ?
                <>File loaded <BsDownload /></>
                : <>Upload File <BsUpload /> </>
              }
              <i className='bi bi-upload'></i>
              <input
                id="file"
                onChange={handleFileUpload}
                style={{ display: "none" }}
                type="file" accept=".xlsx, .xls"
              />
            </label>
            <div className='my-5'>
              <button className='next text-light' style={{ width: "100%" }} onClick={handlesetoptionClick} disabled={!data.length}>Next</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImportData;