import React, { useState } from "react";
import { BiSolidUser, BiDoorOpen } from "react-icons/bi";
import { RiLockPasswordFill } from "react-icons/ri";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { loginclock, logout } from "../../network/api/tilelogs";

function Navbar({ page, image, info, setinfo, showSide, setShowSide }) {
  const cookie = new Cookies();
  const navigate = useNavigate();
  var check = 0;
  const [clockIn, setClockIn] = useState("");
  const [activeDropDwon, setActiveDropDwon] = useState(false);
  const logoutaction = () => {
    logout().then((result) => {
      cookie.remove("crm");
      navigate("/login");
    });
  };
  useEffect(() => {
    const jwtFromCookie = cookie.get("crm");
    
    if (!jwtFromCookie) {
      console.error("JWT token not found in the 'crm' cookie");
      // Handle the case where the token is missing
    } else {
      try {
        const decode = jwtDecode(jwtFromCookie.slice(7));
        setinfo(decode);
      } catch (error) {
        cookie.remove("crm");
        navigate('/login')
        console.log(error.message);
        setinfo({});
      }
    }
    if (clockIn === "" && check === 0 && jwtFromCookie)
      loginclock().then((response) => {
        check = 1;
        if (response.data.success) {
          const responseData = response.data.response;
          const utcClockinTime = new Date(responseData.clocklintime);
          const hours = utcClockinTime.getHours();
          const minutes = utcClockinTime.getMinutes();
          const timeString = `${String(hours).padStart(2, "0")}:${String(
            minutes
          ).padStart(2, "0")}`;
          setClockIn(timeString);
        } else {
          console.error(response.data.message);
        }
      });
  }, []);
  return (
    <nav className="d-flex justify-content-between align-items-center px-2 px-md-4 0">
      <div className="d-flex align-items-start">
        <p
          className="fs-2 d-xl-none m-0 p-0"
          style={{ cursor: "pointer" }}
          onClick={() => setShowSide(true)}
        >
          <BiMenu />
        </p>
        <p className="page-name m-0 p-0 d-none d-lg-block">{page} Page</p>
      </div>
      <div className="d-flex align-items-center">
        <div className="clocks d-flex align-items-center me-1 me-md-4 ">
          <p>Clock In :</p>
          <span className="clock">{clockIn}</span>
        </div>
        {/*<div className='clocks d-flex align-items-center'>
            <p>Clock Out :</p>
            <span className='clock' onClick={() => logoutaction()}>Log Out</span>
    </div>*/}
      </div>
      <div className="nav-user d-flex justify-content-center align-items-center gap-2">
        <img
          src={image}
          width={50}
          style={{ cursor: "pointer" }}
          onClick={() => setActiveDropDwon(!activeDropDwon)}
        />
        <div className="user d-flex flex-column d-none d-lg-block ">
          <p>{info.name}</p>
          <span>{info.role}</span>
        </div>
        {activeDropDwon && (
          <>
            <ul className="dropdown">
              <li className="d-lg-none">
                <div className="user d-flex flex-column">
                  <p>{info.name}</p>
                  <span>{info.role}</span>
                </div>
              </li>
              <li onClick={() => setActiveDropDwon(false)}>
                <BiSolidUser /> Profile
              </li>
              <li onClick={() => setActiveDropDwon(false)}>
                <RiLockPasswordFill /> Change Password
              </li>
              <li onClick={() => logoutaction()}>
                <BiDoorOpen /> Logout
              </li>
            </ul>
            <span className="arrow"></span>
          </>
        )}
      </div>
    </nav>
  );
}
export default Navbar;
