import React, { useEffect, useState } from 'react';

import EditItem from './EditItem';
import LeadsTable from './LeadsTable' 
import { nofollowaction } from '../../network/api/leads';

function NoFollowLeads() {
  const [data, setData] = useState([]);
  const [pageTable, setPageTable] = useState(1)
  const [selectedRows, setSelectedRows] = useState([]);
  const [editingRow, setEditingRow] = useState(-1);
  const [showEdit, setShowEdit] = useState(false);
  const [idOfRow, setIdOfRow] = useState(0)

  const handleRowCheckboxChange = (rowIndex) => {
    if (selectedRows.includes(rowIndex)) {
      setSelectedRows(selectedRows.filter((row) => row !== rowIndex));
      setEditingRow(-1);
    } else {
      setSelectedRows([...selectedRows, rowIndex]);
      setEditingRow(-1);
    }
  };

  useEffect(() => {
    nofollowaction().then(result => {
      setData(result.data.response)
    })
  }, []);


  const handleEditRow = (value) => {
    setShowEdit(true);
    setIdOfRow(value)
  }

  const handleDecreasePageTable = () => {
    if (pageTable > 1) {
      setPageTable(pageTable - 1)
    }
  }

  const handleIncreasePageTable = () => {
    if (pageTable < Math.ceil(data?.length / 12))
      setPageTable(pageTable + 1)
  }

  return (
    <>
      <div className='data-table'>
        <h1 >No Follow</h1>
        <div className='w-100 d-flex flex-column align-items-end ps-4'>
          <div className='table overflow-x-scroll w-100'>
           <LeadsTable data={data}pageTable={pageTable}selectedRows={selectedRows}handleRowCheckboxChange={handleRowCheckboxChange}handleEditRow={handleEditRow}vAction={true} />
          </div>
          <div className='filter py-2 px-5 '>
            <p onClick={() => setPageTable(1)}>&lt;&lt;</p>
            <p onClick={() => handleDecreasePageTable()}>&lt;</p>
           <input 
  type="number" 
  value={pageTable} 
  onChange={(e) => setPageTable(parseInt(e.target.value))}
/>

            <p onClick={() => handleIncreasePageTable()}>&gt;</p>
            <p onClick={() => setPageTable(Math.ceil(data.length / 12))}>&gt;&gt;</p>
          </div>
        </div>
      </div>
      {showEdit && <EditItem data={data} id={idOfRow} setShowEdit={setShowEdit} />}

    </>
  )
}
export default NoFollowLeads;