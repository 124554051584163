import React, { useState, useEffect } from 'react';
import { requests,deleterequest ,acceptrequest} from '../../network/api/company';
import {  getroles } from "../../network/api/freelance";


function JoinRequests() {
  const [pageTable, setPageTable] = useState(1)
  const [roles,setRoles]=useState([])
  const [data, setdata] = useState([]);
  const handleDecreasePageTable = () => {
    if (pageTable > 1) {
      setPageTable(pageTable - 1)
    }
  }
  const handleIncreasePageTable = () => {
    if (pageTable < Math.ceil(data.length / 20))
      setPageTable(pageTable + 1)
  }
  useEffect(() => {
    load()
    getroles().then((result) => {
      setRoles(result.data.roles);
    });
  }, [])
  const load=()=>{
    requests().then(result => {
      setdata(result.data.response)
    })
  }
  const handledeleterequest=async(id)=>{
    console.log(id)
    deleterequest(id).then(()=>{
      load()
    })
  }
  const handleRoleChange=async(e,id)=>{
    acceptrequest(id,e.target.value).then(()=>{
      load()
    })
  }
  return (
    <>
      <div className='data-table'>
        <h1>JoinRequests</h1>
        <div className='w-100 d-flex flex-column align-items-end ps-4'>
          <div className='table overflow-x-scroll w-100'>
            <table style={{ borderCollapse: 'collapse', borderBottom: '1px solid black' }}>
              <thead>
                <tr className=''>
                  <th>name</th>
                  <th>phone</th>
                  <th>email</th>
                  <th>yearsOfExp</th>
                  <th>Accept</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  if (index >= ((pageTable - 1) * 20) && index < pageTable * 20) {
                    return (
                      <tr
                        key={item.Id}
                        id={`row${item._id}`}
                      >
                        <td>{item.requester.name}</td>
                        <td>{item.requester.phone}</td> 
                        <td>{item.requester.email}</td>
                        <td>{item.requester.yearsOfExp}</td>
                        <td><select
            id="mySelect"
            onChange={(e)=>{handleRoleChange(e,item._id)}}
            style={{
              outline: "none",
              fontSize: "20px",
              fontWeight: "600",
              borderRadius: "8px",
              border: "1px solid #4c4c4c",
              background: "#ffffff",
            }}
          >
            {roles.map((onerole,index)=>(
            <option value={onerole}>{onerole===""?"select a role to accept":onerole}</option>
            ))}
          </select>
          <div className='btn text-danger' onClick={()=>{
                          handledeleterequest(item._id)
                        }}>X</div></td>
                        
                      </tr>
                    )
                  }
                })}
              </tbody>
            </table>
          </div>
          <div className='filter py-2 px-5 '>
            <p onClick={() => setPageTable(1)}>&lt;&lt;</p>
            <p onClick={() => handleDecreasePageTable()}>&lt;</p>
           <input 
  type="number" 
  value={pageTable} 
  onChange={(e) => setPageTable(parseInt(e.target.value))}
/>

            <p onClick={() => handleIncreasePageTable()}>&gt;</p>
            <p onClick={() => setPageTable(Math.ceil(data.length / 20))}>&gt;&gt;</p>
          </div>
        </div>
      </div>
    </>
  )
}
export default JoinRequests;


